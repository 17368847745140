import React, { PureComponent, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { getSearchSuggestions, FetchSliders, FetchFeaturedCat, GetSiteSettings, FetchInstagram, SearchLocation, UpdateSelectedLocation, UpdateSelectedDate } from '../Actions/WebsiteActions';
import { Link } from 'react-router-dom';
import TourListingBox from './Common/HomePageTourList';
import { FeaturedActivities, TopSellingActivities, DealsActivities, TopSuggestedActivities, GetTravelInspirations } from '../Actions/ActivityActions';
import { Helmet } from "react-helmet";
import loadingAnim from '../Images/loading.gif';

const $ = window.jQuery;

const mapStateToProps = (state) => {
	return {
		searchResultsLocations: state.Website.searchResultsLocations,
		searchResultsActivities: state.Website.searchResultsActivities,
		featuredTours: state.Website.featuredTours,
		topSellingTours: state.Website.topSellingTours,
		dealsDiscountedTours: state.Website.dealsDiscountedTours,
		sliders: state.Website.sliders,
		featuredCategories: state.Website.featuredCategories,
		site_title: state.Website.site_title,
		site_description: state.Website.site_description,
		bannersLoading: state.Common.bannersLoading,
		instagramFeed: state.Website.instagram,
		suggestedLocations: state.Website.suggestedLocations,
		website: state.Website
	}
}

const mapDispatchToProps = dispatch => ({
	getSearchSuggestions: (keyword, location, date) => dispatch(getSearchSuggestions(keyword, location, date)),
	FeaturedActivities: () => dispatch(FeaturedActivities()),
	TopSellingActivities: () => dispatch(TopSellingActivities()),
	DealsActivities: () => dispatch(DealsActivities()),
	TopSuggestedActivities: () => dispatch(TopSuggestedActivities()),
	FetchSliders: () => dispatch(FetchSliders()),
	FetchFeaturedCat: () => dispatch(FetchFeaturedCat()),
	GetSiteSettings: () => dispatch(GetSiteSettings()),
	FetchInstagram: () => dispatch(FetchInstagram()),
	SearchLocation: (keyword, location, date) => dispatch(SearchLocation(keyword, location, date)),
	UpdateSelectedLocation: (location) => dispatch(UpdateSelectedLocation(location)),
	UpdateSelectedDate: (date) => dispatch(UpdateSelectedDate(date)),
	GetTravelInspirations: (page) => dispatch(GetTravelInspirations(page))
});


function MainSliderItem(props) {
	return "<div class='item' ><div class='slid-itm' style='background-image:url(" + props.image_name + ")'><div  ><div class='slicont'><h3>" + props.title + "</h3><p>" + props.sub_text + "</p></div></div></div></div>";
}

function MainInspirationItem(props) {
	var item = '<div className="item" >';
	item += "<div class='list-inspiration'>";
		item += "<img src='" + props.image_url + "' />";
		item += "<div>";
		item += "<h6>" + props.title + "</h6>";
		item += "<p>" + props.description + "</p>";
		item += "</div>";
		item += "</div>";
		item += "</div>";
	return item
}


function getFeaturedCategories(cats) {
	var mergeCats = [];
	for (var x = 0; x < cats.length; x++) {
		mergeCats.push(cats[x].id);
	}
	return mergeCats.join();
}

function FeaturedCategoryItem(props) {
	var item = "<div class='item'>";
	item += "<div class='list-tor'>";
	item += "<img src='" + props.image_name + "' />";
	item += "<div>";
	item += "<h6>" + props.title + "</h6>";
	item += "<p>" + props.description + "</p>";
	item += "<a class='waves-effect waves-light btn' href='/search?category=" + getFeaturedCategories(props.categories) + "' >View More</a>";
	item += "</div>";
	item += "</div>";
	item += "</div>";

	return item;
}

class Home extends PureComponent {

	owlRef = React.createRef();
	featuredItemsRef = React.createRef();
	travelInspirationsRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
			searchOnFocus: false,

			bannerHeader: "",
			bannerSubText: "",
			sliderChage: false,

			carouselMounted: false,
			slidesLoaded: false,
			inspirationLoaded: false,
			catsLoaded: false,
			selectedLocation: "",
			selectedLocaitonId: null,
			selectedDate: null,
			locationList: false,
			view: null,
		}

		this.props.GetSiteSettings();
		this.props.FetchSliders();
		this.props.FetchFeaturedCat();
		this.props.FeaturedActivities();
		this.props.TopSellingActivities();
		this.props.DealsActivities();
		this.props.TopSuggestedActivities();
		this.props.GetTravelInspirations("home");

		this.searchTimeout = null;

	}


	handleLocationChange = (e) => {
		this.setState({
			locationList: true,
			selectedLocaitonId: null,
			selectedLocation: e.target.value,
			view: 'list'
		}, () => {

			if (this.searchTimeout === null) {
				this.searchTimeout = setTimeout(() => {
					this.searchTimeout = null;
					this.props.SearchLocation(this.state.selectedLocation);
				}, 100)
			}

		});
	}

	handleChange = (event, field) => {
		this.setState({
			[field]: event.target.value,
			view: 'list'
		}, () => {
			this.props.getSearchSuggestions(this.state.searchField, this.state.selectedLocaitonId, this.state.selectedDate);
		});
	}

	onSliderChange = (event) => {
		$('#ch-cont').html("");
		$('#ch-cont').append(window.$("#owl-slider .owl-item.active .slicont").html());
	}

	componentDidMount = () => {
		this.setState({
			carouselMounted: true
		})
	}

	componentDidUpdate = () => {
		if (this.props.sliders !== null && this.state.carouselMounted === true) {
			var slides = "";

			for (var x = 0; x < this.props.sliders.length; x++) {
				slides = slides + MainSliderItem(this.props.sliders[x]);
			}

			if (slides !== "" && this.state.slidesLoaded === false) {
				$(this.owlRef.current).html(slides);
				$(this.owlRef.current).owlCarousel({
					loop: true,
					items: 1,
					nav: false,
					dote: false,
					onChange: this.onSliderChange,
					onInitialized: this.onSliderChange,
					autoplay: true,
					autoplayTimeout: 5000,
					autoplayHoverPause: false
				});
				this.setState({
					slidesLoaded: true
				})
			}
		}
		if (this.props.featuredCategories !== null) {
			var fItems = "";

			for (var x = 0; x < this.props.featuredCategories.length; x++) {
				fItems = fItems + FeaturedCategoryItem(this.props.featuredCategories[x]);
			}

			if (fItems !== "" && this.state.catsLoaded === false) {

				$(this.featuredItemsRef.current).html(fItems);
				$(this.featuredItemsRef.current).owlCarousel({
					loop: false,
					responsiveClass: true,
					responsive: {
						0: {
							items: 1,
							nav: false,
							dote: true,
							loop: true,
							margin: 0
						},
						600: {
							items: 2,
							nav: false,
							dote: true,
							loop: true,
							margin: 30,
						},
						1000: {
							items: 3,
							loop: false,
							nav: false,
							dote: false,
							margin: 30,
						}
					}
				});
				this.setState({
					catsLoaded: true
				})
			}

			if (this.props.suggestedLocations.length > 0) {
				if (this.state.selectedLocaitonId === null) {
					this.setBestSearchedLocation();
				}
			}
		}

		if (this.props.website.travelInspirations !== null) {
			var inspiration = ""
			var travelInspirations = this.props.website.travelInspirations

			for (var x = 0; x < travelInspirations.length; x++) {
				inspiration = inspiration + MainInspirationItem(travelInspirations[x]);
			}

			if (inspiration !== "" && this.state.inspirationLoaded === false) {
				$(this.travelInspirationsRef.current).html(inspiration);
				$(this.travelInspirationsRef.current).owlCarousel({
					loop: false,
					responsiveClass: true,
					responsive: {
						0: {
							items: 1,
							nav: false,
							dote: true,
							loop: true,
							margin: 0
						},
						600: {
							items: 2,
							nav: false,
							dote: true,
							loop: true,
							margin: 30,
						},
						1000: {
							items: 3,
							loop: false,
							nav: false,
							dote: false,
							margin: 30,
						}
					}
				});
				this.setState({
					inspirationLoaded: true
				})
			}
		}

	}


	handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			window.location.href = "/search?key=" + this.state.searchField + (this.state.selectedLocaitonId !== null ? '&location=' + this.state.selectedLocaitonId : "") + (this.state.selectedDate !== null && this.state.selectedDate !== undefined ? '&date=' + (this.state.selectedDate.getFullYear()) + '-' + (this.state.selectedDate.getMonth() + 1) + '-' + (this.state.selectedDate.getDate()) : "") + (this.state.view !== null && this.state.view !== undefined ? '&view=' + (this.state.view) : "");
			e.preventDefault();
		}
	}

	handleSelecLocation = (location) => {
		this.setState({
			selectedLocation: location.label,
			selectedLocaitonId: location.value,
		}, () => {
			this.props.UpdateSelectedLocation(location);
		})
	}

	handleDateChange = (value) => {
		this.setState({ selectedDate: value, view: 'list' }, () => {
			this.props.UpdateSelectedDate(this.state.selectedDate);
		});
	}

	setBestSearchedLocation = () => {
		if (this.props.suggestedLocations !== undefined && this.props.suggestedLocations !== null && this.props.suggestedLocations.length > 0) {
			this.setState({
				selectedLocaitonId: this.props.suggestedLocations[0].value
			});
		}
	}

	render() {
		return <Fragment>
			<Header home={true} />
			<Helmet>
				<meta charSet="utf-8" />
				<title>{this.props.site_title}</title>
				<meta name="description" content={this.props.site_description} />
				<meta property="og:title" content={this.props.site_title} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content="https://aktivitar.com/" />
			</Helmet>
			<section className="slider-section">
				{this.props.bannersLoading === true ? (<div style={{ width: '100%', minHeight: 200, textAlign: 'center', position: 'absolute', top: 150 }} ><img src={loadingAnim} className="loading-animation" /></div>) : null}
				<div className="owl-carousel owl-theme" id="owl-slider" ref={this.owlRef}>



				</div>
				<div className="sit-cont">
					<div className="container">
						<div className="row">
							<div className="col s12">
								<div className="slid-cont">
									<span id="ch-cont">

									</span>
									<div>

										{this.props.bannersLoading === false ? (

											<Fragment>
												<div className="minsrc">
													<div className="pldt">

														<div className="pul">

															<input type="input" onKeyPress={(e) => this.handleEnterKey(e)} onBlur={() => { this.setState({ locationList: false }) }} onFocus={() => { this.setState({ locationList: this.props.suggestedLocations.length > 0 }) }} onChange={this.handleLocationChange} value={this.state.selectedLocation} placeholder="Pick up Location"></input>

															<div className={this.state.locationList === true ? 'opn' : null}>
																<ul>
																	{this.props.suggestedLocations.map((si, index) => (<li key={'a' + index} ><a onClick={() => this.handleSelecLocation(si)} style={{ cursor: 'pointer' }}  >{si.label}</a></li>))}
																</ul>
															</div>
														</div>
														<div className="td">
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<DatePicker
																	onFocus={() => { this.setState({ locationList: false }) }}
																	className="customDatePicker"
																	value={this.state.selectedDate}
																	autoOk={true}
																	format="dd/MM/yyyy"
																	inputVariant="filled"
																	placeholder="Pickup date"
																	onChange={(e) => { this.handleDateChange(e, 'selectedDate') }}
																	style={{ width: "170px" }}
																	id="mui-pickers-date"
																	margin="normal"
																/>
															</MuiPickersUtilsProvider>

														</div>
														<span className="clr-spn"></span>
													</div>
													<div className="inp">
														<input autoComplete="off" onKeyPress={(e) => this.handleEnterKey(e)} placeholder='e.g, "Ella Rock"' id="inphov" type="text" value={this.state.searchField} onBlur={() => { this.setState({ searchOnFocus: false }) }} onFocus={() => { this.setState({ searchOnFocus: true, locationList: false }) }} onChange={(e) => { this.handleChange(e, 'searchField'); }} />
														<input type="submit" value="Search" name="" onClick={() => { window.location.href = "/search?key=" + this.state.searchField + (this.state.selectedLocaitonId !== null ? '&location=' + this.state.selectedLocaitonId : "") + (this.state.selectedDate !== null && this.state.selectedDate !== undefined ? '&date=' + (this.state.selectedDate.getFullYear()) + '-' + (this.state.selectedDate.getMonth() + 1) + '-' + (this.state.selectedDate.getDate()) : "") + (this.state.view !== null && this.state.view !== undefined ? '&view=' + (this.state.view) : "") }} />
														<div id="sil-frm" className={"sr-res " + (this.state.searchOnFocus === true ? 'open' : null)}>
															<div className="rbtt">
																<div className="round_tours">
																	<h4>{this.state.searchField === null ? 'Top' : ''} Round Tours</h4>
																	<ul>
																		{this.props.searchResultsActivities !== null ? this.props.searchResultsActivities.map((activity) => {

																			if (activity.tour_type.id === undefined) {
																				if (activity.tour_type !== 1) { return null }
																				return <li>  <Link to={'/activity/details/' + activity.id}>{activity.title}</Link></li>;
																			} else {
																				if (activity.tour_type.id !== 1) { return null }
																				return <li>  <Link to={'/activity/details/' + activity.id}>{activity.title}</Link></li>;
																			}
																		}) : null}
																	</ul>
																</div>
																<div className="day_tours">
																	<h4>{this.state.searchField === null ? 'Top' : ''} Day Tours</h4>
																	<ul>
																		{this.props.searchResultsActivities !== null ? this.props.searchResultsActivities.map((activity) => {
																			if (activity.tour_type.id === undefined) {
																				if (activity.tour_type !== 2) { return null }
																				return <li>  <Link to={'/activity/details/' + activity.id}>{activity.title}</Link></li>;
																			} else {
																				if (activity.tour_type.id !== 2) { return null }
																				return <li>  <Link to={'/activity/details/' + activity.id}>{activity.title}</Link></li>;
																			}
																		}) : null}
																	</ul>
																</div>
															</div>
															<div className="clear"></div>
														</div>

														<span className="clr-spn"></span>
													</div>
												</div>
											</Fragment>) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="top-gry">
				<div className="container">
					<div className="row">
						<div className="col s12">
							<div className="aegs-row">
								<div>
									<span className="activities"></span>
									<p>+2000 Activities</p>
								</div>
								<div>
									<span className="experience"></span>
									<p>Authentic Experiences</p>
								</div>
								<div>
									<span className="guaranteed"></span>
									<p>Low Price Guaranteed</p>
								</div>
								<div>
									<span className="support"></span>
									<p>24/7 Customer Support</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container">
					<div className="row">
						<div className="col s12">
							<div className="owl-carousel owl-theme owl-tour hid-cont" ref={this.featuredItemsRef} >

							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="gray-back-clk">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="clk-div">
								<img src="/img/passage-of-time.png" />
								<h5>Free Cancellation</h5>
								<p>Free cancellation before 24 hours to the activity starting time</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<TourListingBox title="Made for you" id='wm' data={this.props.featuredTours} all="./search?view=list&featured=1" />
			</section>
			<section className="gray-back-clk">
				<div className="container">
					<div className="row">
						<div className="col s12">
							<div className="clk-div">
								<img src="/img/placeholder.png" />
								<h5>Book at any Location </h5>
								<p>Book activities at the very last minute in any location around the island</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<TourListingBox title="Top Selling" id="ts" data={this.props.topSellingTours} all="./search?view=list&featured=1" />
			</section>

			{this.props.dealsDiscountedTours.length > 0 ? (<section className="discount-secion">
				<TourListingBox title="Deals &amp; Discount" id='dd' data={this.props.dealsDiscountedTours} all="./search?view=list&deals=true" />
			</section>) : null}


			{/* <section className="inspirations-section">
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="inspirations-heading">
								<h4>Travel Inspirations</h4>
							</div>
							
							<div> 
								
							{this.props.website.travelInspirations !== null ? this.props.website.travelInspirations.map((inspiration) => (
									<div className="col l4 s12 item" >
										<div className="list-tor inspirations-ol">
											<img src={inspiration.image_url} />
											<div>
												<h6>{inspiration.title}</h6>
												<p>{inspiration.description}</p>
											</div>
										</div>
									</div>
								)) : null}
							</div>
							{this.state.inspirationLoaded ? <div className="view-all">
								<a href="/travel-inspiration">View All</a>
							</div> :null}
						</div>
					</div>
				</div>
			</section> */}
			<section className="inspirations-section">
				<div className="container">
					
				<div className="inspirations-heading">
								<h4>Travel Inspirations</h4>
							</div>
					<div className="row">
						
						<div className="col s12">
							<div className="owl-carousel owl-theme owl-tour hid-cont" ref={this.travelInspirationsRef} >

							</div>
						</div>
						
						{this.state.inspirationLoaded ? <div className="view-all">
								<a href="/travel-inspiration">View All</a>
							</div> :null}
					</div>
				</div>
			</section>
			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home);
