import React, { PureComponent, Fragment } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer'
import { GetTravelInspirations } from '../Actions/ActivityActions';
import Alert from './Common/Alert';

const mapStateToProps = (state) => {
	return {
		website: state.Website
	}
}

const mapDispatchToProps = dispatch => ({
	GetTravelInspirations: (page) => dispatch(GetTravelInspirations(page))
});

class TravelInspirations extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {

		};
		this.props.GetTravelInspirations("other");
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return <Fragment>
			<Header home={false} />
			<Alert />

			<section className="inspirations-section">
				<div className="container">
					<div className="row">
						<div className="col">
							<div className="hed-bed">
								<div className="bred-cmp">
									<a href="/">Home</a> / <span>Travel Inspirations</span>
								</div>
								<h1>Travel Inspirations</h1>
							</div>
							<div>
								{this.props.website.travelInspirations !== null ? this.props.website.travelInspirations.map((inspiration) => (
									<div className="col l4 s12 item" >
										<div className="list-tor inspirations-ol">
											<img src={inspiration.image_url} />
											<div>
												<h6>{inspiration.title}</h6>
												<p>{inspiration.description}</p>
											</div>
										</div>
									</div>
								)) : null}
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TravelInspirations);