import React, { useState, useEffect } from 'react'
import loadingAnim from '../../Images/loading.gif'

const FooterComp = (props) => {
    const [isLoading, setIsLoading] = useState(props.visible)

    if (isLoading !== props.visible) {
        if (isLoading) {
            setIsLoading(props.visible)
        } else {
            setTimeout(() => {
                setIsLoading(props.visible)
            }, 2000)
        }
    }
    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isLoading]);

    return (<div className={isLoading ? 'loader' : null}>
        {isLoading ? (
            <div style={{ width: '100%', minHeight: 200, textAlign: 'center', position: 'absolute', top: 150 }} >
                <img src={loadingAnim} className="loading-animation" />
            </div>) : null}
    </div>);
};

export default FooterComp;
